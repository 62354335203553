/* MetricHPE web fonts */


@font-face {
  font-family: "MetricHPE-Web-Regular";
  src: local('MetricHPE-Web-Regular'), url("./fonts/MetricHPE-Web-Regular.eot");
  src: local('MetricHPE-Web-Regular'), url("./fonts/MetricHPE-Web-Regular.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-Regular'), url("./fonts/MetricHPE-Web-Regular.woff") format("woff"),
    local('._MetricHPE-Web-Regular'), url("./fonts/._MetricHPE-Web-Regular.eot"),
    local('._MetricHPE-Web-Regular'), url("./fonts/._MetricHPE-Web-Regular.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-Regular'), url("./fonts/._MetricHPE-Web-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-Black";
  src: local('MetricHPE-Web-Black'), url("./fonts/MetricHPE-Web-Black.eot");
  src: local('MetricHPE-Web-Black'), url("./fonts/MetricHPE-Web-Black.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-Black'), url("./fonts/MetricHPE-Web-Black.woff") format("woff"),
    local('._MetricHPE-Web-Black'), url("./fonts/._MetricHPE-Web-Black.eot"),
    local('._MetricHPE-Web-Black'), url("./fonts/._MetricHPE-Web-Black.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-Black'), url("./fonts/._MetricHPE-Web-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-BlackItalic";
  src: local('MetricHPE-Web-BlackItalic'), url("./fonts/MetricHPE-Web-BlackItalic.eot");
  src: local('MetricHPE-Web-BlackItalic'), url("./fonts/MetricHPE-Web-BlackItalic.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-BlackItalic'), url("./fonts/MetricHPE-Web-BlackItalic.woff") format("woff"),
    local('._MetricHPE-Web-BlackItalic'), url("./fonts/._MetricHPE-Web-BlackItalic.eot"),
    local('._MetricHPE-Web-BlackItalic'), url("./fonts/._MetricHPE-Web-BlackItalic.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-BlackItalic'), url("./fonts/._MetricHPE-Web-BlackItalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-Bold";
  src: local('MetricHPE-Web-Bold'), url("./fonts/MetricHPE-Web-Bold.eot");
  src: local('MetricHPE-Web-Bold'), url("./fonts/MetricHPE-Web-Bold.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-Bold'), url("./fonts/MetricHPE-Web-Bold.woff") format("woff"),
    local('._MetricHPE-Web-Bold'), url("./fonts/._MetricHPE-Web-Bold.eot"),
    local('._MetricHPE-Web-Bold'), url("./fonts/._MetricHPE-Web-Bold.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-Bold'), url("./fonts/._MetricHPE-Web-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-BoldItalic";
  src: local('MetricHPE-Web-BoldItalic'), url("./fonts/MetricHPE-Web-BoldItalic.eot");
  src: local('MetricHPE-Web-BoldItalic'), url("./fonts/MetricHPE-Web-BoldItalic.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-BoldItalic'), url("./fonts/MetricHPE-Web-BoldItalic.woff") format("woff"),
    local('._MetricHPE-Web-BoldItalic'), url("./fonts/._MetricHPE-Web-BoldItalic.eot"),
    local('._MetricHPE-Web-BoldItalic'), url("./fonts/._MetricHPE-Web-BoldItalic.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-BoldItalic'), url("./fonts/._MetricHPE-Web-BoldItalic.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "MetricHPE-Web-Light";
  src: local('MetricHPE-Web-Light'), url("./fonts/MetricHPE-Web-Light.eot");
  src: local('MetricHPE-Web-Light'), url("./fonts/MetricHPE-Web-Light.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-Light'), url("./fonts/MetricHPE-Web-Light.woff") format("woff"),
    local('._MetricHPE-Web-Light'), url("./fonts/._MetricHPE-Web-Light.eot"),
    local('._MetricHPE-Web-Light'), url("./fonts/._MetricHPE-Web-Light.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-Light'), url("./fonts/._MetricHPE-Web-Light.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-LightItalic";
  src: local('MetricHPE-Web-LightItalic'), url("./fonts/MetricHPE-Web-LightItalic.eot");
  src: local('MetricHPE-Web-LightItalic'), url("./fonts/MetricHPE-Web-LightItalic.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-LightItalic'), url("./fonts/MetricHPE-Web-LightItalic.woff") format("woff"),
    local('._MetricHPE-Web-LightItalic'), url("./fonts/._MetricHPE-Web-LightItalic.eot"),
    local('._MetricHPE-Web-LightItalic'), url("./fonts/._MetricHPE-Web-LightItalic.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-LightItalic'), url("./fonts/._MetricHPE-Web-LightItalic.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "MetricHPE-Web-Medium";
  src: local('MetricHPE-Web-Medium'), url("./fonts/MetricHPE-Web-Medium.eot");
  src: local('MetricHPE-Web-Medium'), url("./fonts/MetricHPE-Web-Medium.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-Medium'), url("./fonts/MetricHPE-Web-Medium.woff") format("woff"),
    local('._MetricHPE-Web-Medium'), url("./fonts/._MetricHPE-Web-Medium.eot"),
    local('._MetricHPE-Web-Medium'), url("./fonts/._MetricHPE-Web-Medium.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-Medium'), url("./fonts/._MetricHPE-Web-Medium.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-MediumItalic";
  src: local('MetricHPE-Web-MediumItalic'), url("./fonts/MetricHPE-Web-MediumItalic.eot");
  src: local('MetricHPE-Web-MediumItalic'), url("./fonts/MetricHPE-Web-MediumItalic.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-MediumItalic'), url("./fonts/MetricHPE-Web-MediumItalic.woff") format("woff"),
    local('._MetricHPE-Web-MediumItalic'), url("./fonts/._MetricHPE-Web-MediumItalic.eot"),
    local('._MetricHPE-Web-MediumItalic'), url("./fonts/._MetricHPE-Web-MediumItalic.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-MediumItalic'), url("./fonts/._MetricHPE-Web-MediumItalic.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-RegularItalic";
  src: local('MetricHPE-Web-RegularItalic'), url("./fonts/MetricHPE-Web-RegularItalic.eot");
  src: local('MetricHPE-Web-RegularItalic'), url("./fonts/MetricHPE-Web-RegularItalic.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-RegularItalic'), url("./fonts/MetricHPE-Web-RegularItalic.woff") format("woff"),
    local('._MetricHPE-Web-RegularItalic'), url("./fonts/._MetricHPE-Web-RegularItalic.eot"),
    local('._MetricHPE-Web-RegularItalic'), url("./fonts/._MetricHPE-Web-RegularItalic.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-RegularItalic'), url("./fonts/._MetricHPE-Web-RegularItalic.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-Semibold";
  src: local('MetricHPE-Web-Semibold'), url("./fonts/MetricHPE-Web-Semibold.eot");
  src: local('MetricHPE-Web-Semibold'), url("./fonts/MetricHPE-Web-Semibold.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-Semibold'), url("./fonts/MetricHPE-Web-Semibold.woff") format("woff"),
    local('._MetricHPE-Web-Semibold'), url("./fonts/._MetricHPE-Web-Semibold.eot"),
    local('._MetricHPE-Web-Semibold'), url("./fonts/._MetricHPE-Web-Semibold.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-Semibold'), url("./fonts/._MetricHPE-Web-Semibold.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-SemiboldItalic";
  src: local('MetricHPE-Web-SemiboldItalic'), url("./fonts/MetricHPE-Web-SemiboldItalic.eot");
  src: local('MetricHPE-Web-SemiboldItalic'), url("./fonts/MetricHPE-Web-SemiboldItalic.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-SemiboldItalic'), url("./fonts/MetricHPE-Web-SemiboldItalic.woff") format("woff"),
    local('._MetricHPE-Web-SemiboldItalic'), url("./fonts/._MetricHPE-Web-SemiboldItalic.eot"),
    local('._MetricHPE-Web-SemiboldItalic'), url("./fonts/._MetricHPE-Web-SemiboldItalic.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-SemiboldItalic'), url("./fonts/._MetricHPE-Web-SemiboldItalic.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-Thin";
  src: local('MetricHPE-Web-Thin'), url("./fonts/MetricHPE-Web-Thin.eot");
  src: local('MetricHPE-Web-Thin'), url("./fonts/MetricHPE-Web-Thin.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-Thin'), url("./fonts/MetricHPE-Web-Thin.woff") format("woff"),
    local('._MetricHPE-Web-Thin'), url("./fonts/._MetricHPE-Web-Thin.eot"),
    local('._MetricHPE-Web-Thin'), url("./fonts/._MetricHPE-Web-Thin.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-Thin'), url("./fonts/._MetricHPE-Web-Thin.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetricHPE-Web-ThinItalic";
  src: local('MetricHPE-Web-ThinItalic'), url("./fonts/MetricHPE-Web-ThinItalic.eot");
  src: local('MetricHPE-Web-ThinItalic'), url("./fonts/MetricHPE-Web-ThinItalic.eot#iefix") format("embedded-opentype"),
    local('MetricHPE-Web-ThinItalic'), url("./fonts/MetricHPE-Web-ThinItalic.woff") format("woff"),
    local('._MetricHPE-Web-ThinItalic'), url("./fonts/._MetricHPE-Web-ThinItalic.eot"),
    local('._MetricHPE-Web-ThinItalic'), url("./fonts/._MetricHPE-Web-ThinItalic.eot#iefix") format("embedded-opentype"),
    local('._MetricHPE-Web-ThinItalic'), url("./fonts/._MetricHPE-Web-ThinItalic.woff") format("woff"); 
  font-weight: normal;
  font-style: normal;
}



/* HPE SIMPLE WEB FONTS */


@font-face {
  font-family: "HPESimpleWeb-Regular";
  src: local('HPESimpleWeb-Regular'), url("./fonts/HPESimpleWeb-Regular.eot");
  src: local('HPESimpleWeb-Regular'), url("./fonts/HPESimpleWeb-Regular.eot#iefix") format("embedded-opentype"),
    local('HPESimpleWeb-Regular'), url("./fonts/HPESimpleWeb-Regular.woff2") format("woff2"),
    local('HPESimpleWeb-Regular'), url("./fonts/HPESimpleWeb-Regular.woff") format("woff"),
    local('HPESimpleWeb-Regular'), url("./fonts/HPESimpleWeb-Regular.ttf") format("truetype"),
    local('HPESimpleWeb-Regular'), url("./fonts/HPESimpleWeb-Regular.svg#HPESimpleWebRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "HPESimpleWeb-Oblique";
  src: url("./fonts/HPESimpleWeb-Oblique.eot");
  src: url("./fonts/HPESimpleWeb-Oblique.eot#iefix") format("embedded-opentype"),
       url("./fonts/HPESimpleWeb-Oblique.woff2") format("woff2"),
       url("./fonts/HPESimpleWeb-Oblique.woff") format("woff"),
       url("./fonts/HPESimpleWeb-Oblique.ttf") format("truetype"),
       url("./fonts/HPESimpleWeb-Oblique.svg#HPESimpleWebOblique") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HPESimpleWeb-Light";
  src: url("./fonts/HPESimpleWeb-Light.eot");
  src: url("./fonts/HPESimpleWeb-Light.eot#iefix") format("embedded-opentype"),
       url("./fonts/HPESimpleWeb-Light.woff2") format("woff2"),
       url("./fonts/HPESimpleWeb-Light.woff") format("woff"),
       url("./fonts/HPESimpleWeb-Light.ttf") format("truetype"),
       url("./fonts/HPESimpleWeb-Light.svg#HPESimpleWebLight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HPESimpleWeb-LightOblique";
  src: url("./fonts/HPESimpleWeb-LightOblique.eot");
  src: url("./fonts/HPESimpleWeb-LightOblique.eot#iefix") format("embedded-opentype"),
       url("./fonts/HPESimpleWeb-LightOblique.woff2") format("woff2"),
       url("./fonts/HPESimpleWeb-LightOblique.woff") format("woff"),
       url("./fonts/HPESimpleWeb-LightOblique.ttf") format("truetype"),
       url("./fonts/HPESimpleWeb-LightOblique.svg#HPESimpleWebLightOblique") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HPESimpleWeb-Bold";
  src: url("./fonts/HPESimpleWeb-Bold.eot");
  src: url("./fonts/HPESimpleWeb-Bold.eot#iefix") format("embedded-opentype"),
       url("./fonts/HPESimpleWeb-Bold.woff2") format("woff2"),
       url("./fonts/HPESimpleWeb-Bold.woff") format("woff"),
       url("./fonts/HPESimpleWeb-Bold.ttf") format("truetype"),
       url("./fonts/HPESimpleWeb-Bold.svg#HPESimpleWebBold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HPESimpleWeb-BoldOblique";
  src: url("./fonts/HPESimpleWeb-BoldOblique.eot");
  src: url("./fonts/HPESimpleWeb-BoldOblique.eot#iefix") format("embedded-opentype"),
       url("./fonts/HPESimpleWeb-BoldOblique.woff2") format("woff2"),
       url("./fonts/HPESimpleWeb-BoldOblique.woff") format("woff"),
       url("./fonts/HPESimpleWeb-BoldOblique.ttf") format("truetype"),
       url("./fonts/HPESimpleWeb-BoldOblique.svg#HPESimpleWebBoldOblique") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#chathpe_table_response,#chathpe_table_response>thead>tr>th,#chathpe_table_response>tbody>tr>td{
  border: 1px solid mediumseagreen;
  border-collapse: collapse;
}
#chathpe_table_response>thead{
  background-color: lightgrey;
}
