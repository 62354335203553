.App {
  background-color: #F7F7F7;
  color: #333333;
  font-family: "MetricHPE-Web-Regular";
}

.App .sessions {
  background-color: #FFFFFF;
  border-color: #0000001F;
  font-family: "MetricHPE-Web-Regular";
}

.App button svg {
  fill: #01A982;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App.light input,
.App.light textarea {
  /* THIS CHANGES THE TEXT BAR FONT */
  color: #555555;
  font-family: "MetricHPE-Web-Regular";
  font-size: 16px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App.light span {
  /* CHANGES THE SESSION LIST TEXT PROPERTIES */
  /* color: #555555; */
  /* font-family: "MetricHPE-Web-Regular"; */
  font-size: 16px;
}

.App.light .scrollToBottom {
  background-color: transparent;
  border-radius: 50px;
  
}

.light.sessions-text {
  color: #555555;
}

.dark.sessions-text {
  color: #FFFFFF;
}

.light.tooltip {
  color: #555555;
  border-radius: '16px';
  font-family: "MetricHPE-Web-Regular";
  font-size: 14px;
}

.light.userIcon {
  background-color: #7630EA;
  color: #FFFFFF;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

ul {
  padding: 8px 8px;
}

ul li {
  font-family: "MetricHPE-Web-Regular";
  list-style: none;
  margin: 0;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 8px;
  /* margin: 0 8px; */
  /* height: 40px; */
  color: #555555;
  line-height: 25px;
  font-size: 16px;
}

ul li>button {
  height: 25px;
}

.list-group-item:focus,
.list-group-item.active:focus,
li div,
.sessions div,
input,
textarea,
button,
[role='radiogroup'] div {
  box-shadow: none !important;
}

.input-box:focus {
  border: none;
}

.light .userChatBubble {
  background-color: #FFFFFF;
  max-width: 100%;
  overflow-x: auto;
  /* font-size: 16px;
  font-family: "MetricHPE-Web-Regular"; */
}

.light .systemChatBubble {
  background-color: #FFFFFF;
  max-width: 100%;
  overflow-x: auto;
  /* font-size: 16px;
  font-family: "MetricHPE-Web-Regular"; */
}

.light .systemUserIcon {
  background-color: #F7F7F7;
}

.markdown-content {
  word-wrap: break-word;
  white-space: pre-wrap; /* Preserves whitespace and line breaks */
  overflow-wrap: break-word; /* Breaks long words to prevent overflow */
  /* Ensure you have a defined width or max-width for the container */
  max-width: 100%; /* Example: Adjust as needed */
}

.demo {
  border: .3rem solid transparent !important;
  background-image:
    linear-gradient(to right, #FFFFFF, #FFFFFF),
    linear-gradient(to right, #00E8CF, #7630EA, #FF8300, #FEC901) !important;
  background-origin: padding-box, border-box !important;
  background-clip: padding-box, border-box !important;
  background-repeat: no-repeat !important;
  padding: none !important;
  display: inline-block;
  border-radius: 16px !important;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0px 6px 12px #0000001F;
}

.light.loadingPane {
  background-color: #FFFFFF;
  color: #555555;
  border-radius: 12px;
}

.demo:after {
  filter: blur(50px);
}

.light.settingPane {
  background-color: #F7F7F7;
  color: #333333;
  font-family: "MetricHPE-Web-Regular";
  font-size: 16px;
  border-radius: 12px;
}

input.progress::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #6633BC;
}

.list-group-item.active {
  color: #333333;
  font-family: "MetricHPE-Web-Medium";
  background-color: #0000000A;
  border-radius: 12px;
  border: 1px solid #0000005C;
}

.list-group-item:hover {
  color: #333333;
  font-family: "MetricHPE-Web-Medium";
  /* background-color: #0000000A; */
  border: 1px solid #0000005C;
  border-radius: 12px;
}

li button[aria-label="Open Menu"] {
  fill: #FFFFFF;
  stroke: #FFFFFF;
  display: none;
}

.list-group-item.active button[aria-label="Open Menu"] div {
  padding: 0;
}

.list-group-item.active button[aria-label="Open Menu"] {
  /* fill: rgb(68, 68, 68);
  stroke: rgb(68, 68, 68); */
  display: block;
}

.light.list-panel {
  border-radius: 12px !important;
  color: #555555;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
}

.light.list-panel div[role="menu"] {
  font-size: 16px;
  color: #555555;
  line-height: 12px;
  font-family: "MetricHPE-Web-Regular";
}

.light.list-panel div[role="menu"]>div {
  /* border-bottom: 1px solid; */
  color: #555555;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
}

.light.list-panel div[role="menu"]>div:last-child {
  border: none;
  background-color: #FC5A5A;
  border-top: 1px solid;
  color: #555555;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
}

/* ########################################################################## */
.light.chat-mode-selection {
  border-radius: 12px !important;
  color: #555555;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
}

.light.chat-mode-selection div[role="menu"] {
  font-size: 16px;
  color: #555555;
  line-height: 12px;
  font-family: "MetricHPE-Web-Regular";
}

.light.chat-mode-selection div[role="menu"]>div {
  /* border-bottom: 1px solid; */
  color: #555555;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
}

.light.chat-mode-selection div[role="menu"]>div:last-child {
  border: none;
  color: #555555;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
}

/* ########################################################################## */

.dark.chat-mode-selection {
  border-radius: 12px !important;
  background-color: #222222;
  color: #FFFFFF;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
}

.dark.chat-mode-selection div[role="menu"] {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 12px;
  font-family: "MetricHPE-Web-Regular";
}

.darkchat-mode-selection div[role="menu"]>div {
  /* border-bottom: 1px solid; */
  background-color: #222222;
  color: #FFFFFF;
  font-family: "MetricHPE-Web-Regular";
}

.darkchat-mode-selection div[role="menu"]>div:last-child {
  border: none;
  background-color: #222222;
  color: #FFFFFF;
  font-family: "MetricHPE-Web-Regular";
}

/* ########################################################################## */

.light.new-session-plus-button {
  background-color: #01A982;
  border-radius: 50px;
  font-family: "MetricHPE-Web-Bold";
  margin-right: 8px;
}

.dark.new-session-plus-button {
  background-color: #01A982;
  border-radius: 50px;
  font-family: "MetricHPE-Web-Bold";
  margin-right: 8px;
}

/* ########################################################################## */

.light.deletePane {
  background-color: #F7F7F7;
  color: #555555;
  font-size: 16px;
  border-radius: 12px;
}

.light.deletePaneDeleteButton {
  background-color: #FC5A5A;
  font-family: "MetricHPE-Web-Bold";
  font-size: 16px;
}

.light.warningBox {
  background-color: #F7F7F7;
  color: #555555;
  font-family: "MetricHPE-Web-Regular";
  border: transparent;
  border-radius: 12px;
}

.light.feedbackBox {
  background-color: #F7F7F7;
  color: #555555;
  border-radius: 12px;
}

.light.feedbackBoxCancelButton {
  color: #555555;
  border-color: #0000005C;
}

div[role="menu"]>div {
  /* border-bottom: 1px solid; */
  font-size: 16px;
}

div[role="menu"] {
  font-size: 16px;
  line-height: 12px;
}

stack {
  text-align: left;
}

.thumbs svg {
  width: 15px;
  height: 15px;
}

.thumbs svg:focus {
  border: none;
}

Paragraph {
  border-right: 2px solid transparent;
  font-family: "MetricHPE-Web-Regular";
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  animation: typing-animation 1s steps(3000, end);
}

@keyframes typing-animation {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.light * {
  scrollbar-width: thin;
  scrollbar-color: #0000005C #0000000A;
}

.dark * {
  scrollbar-width: thin;
  scrollbar-color: #FFFFFF5C #FFFFFF0F;
}

/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 8px;
}

.light ::-webkit-scrollbar-track {
  background: #0000000A;
  border-radius: 12px;
}

.light ::-webkit-scrollbar-thumb {
  background-color: #0000005C;
  border-radius: 12px;
  border: 1px solid #FFFFFF0F;
}

.dark ::-webkit-scrollbar-track {
  background: #FFFFFF0F;
  border-radius: 12px;
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #FFFFFF5C;
  border-radius: 12px;
  border: 1px solid #FFFFFF0F;
}

header {
  z-index: 9 !important;
}
/* ============================================ START OF DARK THEME CSS CODE ============================================ */
.App.dark {
  background-color: #222222;
}
.App.dark .sessions {
  background-color: #1C1C1C;
  border-color: #FFFFFF1F;
  font-size: 16px;
}

.App.dark ul li,
.App.dark {
  color: #FFFFFF;
  font-size: 16px;
}

.App.dark stack span {
  color: #000;
}

.App.dark span {
  color: #FFFFFF80;
  /* font-family: "MetricHPE-Web-Regular"; */
  font-size: 14px;
}

.App.dark svg {
  fill: rgba(200, 200, 200, 1);
  stroke: rgba(200, 200, 200, 1);
}

.App.dark button svg {
  fill: #01A982;
}

.App.dark input,
.App.dark textarea {
  color: #FFFFFF;
  /* font-family: "MetricHPE-Web-Regular"; */
  font-size: 16px;
}

.dark .list-group-item.active {
  background-color: #FFFFFF0F; 
  border: 1px solid #FFFFFF5C;
}

.dark .list-group-item:hover {
  /* background-color: #FFFFFF0F; */
  border: 1px solid #FFFFFF5C;
}

.dark .userChatBubble {
  background-color: #FFFFFF0F;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
  max-width: 100%;
  overflow-x: auto;
}

.dark .systemChatBubble {
  background-color: #FFFFFF0F;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
  max-width: 100%;
  overflow-x: auto;
}

.dark.list-panel {
  border-radius: 12px !important;
  background-color: #222222;
  color: #FFFFFF;
  font-size: 16px;
  font-family: "MetricHPE-Web-Regular";
}

.dark.list-panel div[role="menu"] {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 12px;
  font-family: "MetricHPE-Web-Regular";
}

.dark.list-panel div[role="menu"]>div {
  /* border-bottom: 1px solid; */
  background-color: #222222;
  color: #FFFFFF;
  font-family: "MetricHPE-Web-Regular";
}

.dark.list-panel div[role="menu"]>div:last-child {
  border: none;
  background-color: #D04F4E;
  border-top: 1px solid;
  color: #FFFFFF;
  font-family: "MetricHPE-Web-Regular";
}

.dark .demo {
  border: .3rem solid transparent !important;
  background-image:
    linear-gradient(to right, #222222, #222222),
    linear-gradient(to right, #00E8CF, #7630EA, #FF8300, #FEC901) !important;
  background-origin: padding-box, border-box !important;
  background-clip: padding-box, border-box !important;
  background-repeat: no-repeat !important;
  padding: none !important;
  box-shadow: 0px 6px 12px #0000005C;
}

.dark.loadingPane {
  background-color: #222222;
  color: #FFFFFF;
  border-radius: 12px;
}

.dark .demo:after {
  filter: blur(50px);
}

.dark.settingPane {
  background-color: #222222;
  color: #FFFFFF;
  font-family: "MetricHPE-Web-Regular";
  font-size: 16px;
  border-radius: 12px;
}

.dark.deletePane {
  background-color: #222222;
  color: #FFFFFF;
  border-radius: 12px;

}

.dark.deletePaneDeleteButton {
  background-color: #D04F4E;
}

.dark.userIcon {
  background-color: #7630EA;
  color: #FFFFFF;
}

.dark.feedbackBox {
  background-color: #222222;
  color: #FFFFFF;
  border-radius: 12px;
}

.dark.feedbackBoxCancelButton {
  color: #FFFFFF;
  border-color: #FFFFFF5C;
}

Tip {
  font-size: 10px;
}

form label {
  font-size: 16px !important;
}

form textarea {
  font-size: 16px !important;
  font-weight: normal !important;
}

button div {
  font-size: 16px;
}

.dark.tooltip {
  color: #FFFFFF;
  font-size: 12px;
}

.App.dark .chat-history span {
  color: #FFFFFF;
  font-size: 16px;
}

.App.dark .scrollToBottom {
  background-color: transparent;
  border-radius: 50%;
}

.dark.warningBox {
  background-color: #1C1C1C;
  color: #FFFFFF;
  font-family: "MetricHPE-Web-Regular";
}

@media only screen and (min-width: 1900px) {
  .chat-container {
    max-height: 79% !important;
  }
  .scrollToBottom {
    bottom: 10% !important;
  }
}

/* Document Upload Sub Bar Theme */

.light.file-action-bar {
  border-color: #0000000A;
  background: #F5F5F5;
  border-radius: 0 0 16px 16px;
}

.dark.file-action-bar {
  border-color: #FFFFFF0F;
  background: #2F2F2F;
  border-radius: 0 0 16px 16px;
}

.light.attach-file-button {
  padding: 8px;
  color: #222222;
  border: none;
}

.light.attach-file-button-icon {
  color: #222222;
}

.light.sample-prompt-button {
  padding: 8px;
  color: #222222;
  border: none;
}

.light.sample-prompt-button-icon {
  color: #222222;
}

.dark.attach-file-button {
  padding: 8px;
  color: #FFFFFF;
  border: none;
}

.dark.attach-file-button-icon {
  color: #FFFFFF;
}

.dark.sample-prompt-button {
  padding: 8px;
  color: #FFFFFF;
  border: none;
}

.dark.sample-prompt-button-icon {
  color: #FFFFFF;
}

.light.file-name-tag-box {
  font-family: "MetricHPE-Web-Regular";
}
 
.dark.file-name-tag-box {
  font-family: "MetricHPE-Web-Regular";
}
 
.light.unprocessed-file-name-tag {
  margin-right: 8px;
  font-size: 12px;
  font-family: "MetricHPE-Web-Regular";
}
 
.dark.unprocessed-file-name-tag {
  margin-right: 8px;
  font-size: 12px;
  font-family: "MetricHPE-Web-Regular";
}

.light.processed-file-name-tag {
  margin-right: 8px;
  font-size: 12px;
  font-family: "MetricHPE-Web-Regular";
}
 
.dark.processed-file-name-tag {
  margin-right: 8px;
  font-size: 12px;
  font-family: "MetricHPE-Web-Regular";
}

.light.meter {
  border-radius: 12px;
}

.dark.meter {
  border-radius: 12px;
}

.light.regenButton {
  color: #555555;
  border-radius: 10px;
  border-color: #0000001F;
  padding: 3px 5px 3px 5px;
  margin-bottom: 15px;
  font-size: 12px;
  font-family: "MetricHPE-Web-Regular";
  animation: easeIn .3s ease-in-out 0s 1 normal forwards;
  animation: easeOut .3s ease-in-out 0s 1 normal reverse;
}

.dark.regenButton {
  color: #FFFFFF;
  border-radius: 10px;
  border-color: #FFFFFF1F;
  padding: 3px 5px 3px 5px;
  margin-bottom: 15px;
  font-size: 12px;
  font-family: "MetricHPE-Web-Regular";
  animation: easeIn .3s ease-in-out 0s 1 normal forwards;
  animation: easeOut .3s ease-in-out 0s 1 normal reverse;
}

.light.scrollToBottomButton {
  color: #555555;
  background-color: #FFFFFF;
  border-radius: 10px;
  border-color: #0000001F;
  padding: 5px 10px 5px 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: "MetricHPE-Web-Regular";
  animation: easeIn .3s ease-in-out 0s 1 normal forwards;
  animation: easeOut .3s ease-in-out 0s 1 normal reverse;
}

.dark.scrollToBottomButton {
  color: #FFFFFF;
  background-color: #222222;
  border-radius: 10px;
  border-color: #FFFFFF1F;
  padding: 5px 10px 5px 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: "MetricHPE-Web-Regular";
  animation: easeIn .3s ease-in-out 0s 1 normal forwards;
  animation: easeOut .3s ease-in-out 0s 1 normal reverse;
}

.light.settingsCard {
  overflow: 'hidden';
}

.dark.settingsCard {
  background-color: #1C1C1C;
  overflow: 'hidden';
}

.light.settingsCardHeader {
  overflow: 'hidden';
}

.dark.settingsCardHeader {
  background-color: #1C1C1C;
}

.light.settingsCardBody {
  color: #555555;
  background-color: #FFFFFF;
  border-radius: 12px 12px 0 0 !important;
}

.dark.settingsCardBody {
  color: #FFFFFF;
  background-color: #1C1C1C;
  border-radius: 12px 12px 0 0 !important;
}

.light.settingsCardFooter {
  background-color: #F7F7F7;
  border-radius: 0 0 12px 12px !important;
}

.dark.settingsCardFooter {
  color: #FFFFFF;
  background-color: #222222;
  border-radius: 0 0 12px 12px !important;
}

.light.userSettingsDropContent {
  border-radius: 12px !important;
  font-size: 16px;
  border: none;
  font-family: "MetricHPE-Web-Regular";
  background-color: #FFFFFF;
}

.dark.userSettingsDropContent {
  border-radius: 12px !important;
  overflow: hidden;
  font-size: 16px;
  border: none;
  font-family: "MetricHPE-Web-Regular";
  background-color: #1C1C1C;
}

.light.confirmationPopup {
  background-color: #F7F7F7;
  color: #333333;
  font-family: "MetricHPE-Web-Regular";
  border-radius: 14px;
}

.dark.confirmationPopup {
  background-color: #222222;
  color: #FFFFFF;
  font-family: "MetricHPE-Web-Regular";
  border-radius: 14px;
}

@keyframes easeIn {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes easeOut {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}